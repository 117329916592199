import React, { useRef } from 'react'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { defaultLang } from '../../../config'
import { getSlug } from '../../../utils'
const previewClasses = {
  img: 'bgWrapper',
  title: 'categoryItemInfo',
  box: 'imageLeftBox imageBox',
}

export default function CategoryItemsList({ data, locale }) {
  const oddItems = data.filter((_item, idx) => idx % 2 !== 0)
  const evenItems = data.filter((_item, idx) => idx % 2 === 0)
  const titleRefs = useRef({})
  const imgRefs = useRef({})
  const boxRefs = useRef({})
  const changeBackground = id => {
    for (let key in imgRefs.current) {
      imgRefs.current[key].className = previewClasses.img
      titleRefs.current[key].className = previewClasses.title
      boxRefs.current[key].className = previewClasses.box
    }
    imgRefs.current[id].className = previewClasses.img + ' active'
    titleRefs.current[id].className = previewClasses.title + ' active'
    boxRefs.current[id].className = previewClasses.box + ' active'
  }
  return (
    <>
      <div className="itemListContainer">
        <div className="listContainerContent">
          {data.map(({ node }, i) => {
            const { frontmatter, fields } = node
            const { id, title, date } = frontmatter
            const slug = getSlug(fields.slug)
            const defActive = i === 0 ? 'active' : ''
            return (
              <div
                role="presentation"
                onMouseEnter={() => changeBackground(id)}
                className="categoryItemBody"
                key={i}
              >
                <div
                  role="presentation"
                  onClick={() => {
                    const pathLocale = defaultLang ? '' : '/' + locale
                    window.location.href = `${pathLocale}/projects/${slug}`
                  }}
                  className={`categoryItemInfo ${defActive}`}
                  ref={el => (titleRefs.current[id] = el)}
                >
                  <span className="categoryItemTitle">{title}</span>
                  <span className="categoryItemDate">{date}</span>{' '}
                </div>
              </div>
            )
          })}
        </div>
        <div className="listContainerImages">
          <div className="leftBox box">
            {evenItems.map(({ node }, i) => {
              let bottom = 200 * i
              const defActive = i === 0 ? 'active' : ''
              return (
                <div
                  style={{ bottom }}
                  ref={el => (boxRefs.current[node.frontmatter.id] = el)}
                  className={`imageLeftBox imageBox ${defActive}`}
                  key={i}
                >
                  <BgImage image={getImage(node.frontmatter.galleryImages[0])}>
                    <div
                      ref={el => (imgRefs.current[node.frontmatter.id] = el)}
                      className={`bgWrapper ${defActive}`}
                    ></div>
                  </BgImage>
                </div>
              )
            })}
          </div>
          <div className="rightBox box">
            {oddItems.map(({ node }, i) => {
              let bottom = 200 * i
              return (
                <div
                  style={{ bottom }}
                  className="imageRightBox imageBox"
                  ref={el => (boxRefs.current[node.frontmatter.id] = el)}
                  key={i}
                >
                  <BgImage image={getImage(node.frontmatter.galleryImages[0])}>
                    <div
                      ref={el => (imgRefs.current[node.frontmatter.id] = el)}
                      className="bgWrapper"
                    ></div>
                  </BgImage>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="CategoryItemsList-hidden">
        {data.map(({ node }, i) => {
          const slug = getSlug(node.fields.slug)
          return (
            <BgImage image={getImage(node.frontmatter.galleryImages[0])} key={i}>
              <div className="categoryItemBody">
                <div className="categoryItemInfo">
                  <span
                    className="categoryItemTitle"
                    role="presentation"
                    onClick={() => {
                      const pathLocale = defaultLang ? '' : '/' + locale
                      window.location.href = `${pathLocale}/projects/${slug}`
                    }}
                  >
                    {node.frontmatter.title}
                  </span>
                  <span className="categoryItemDate">{node.frontmatter.date}</span>
                </div>
              </div>
            </BgImage>
          )
        })}
      </div>
    </>
  )
}
