import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import ArrowLight from '../images/assets/line-light.svg'
import CategoryItemsList from '../components/pages/projects/CategoryItemsList'
import Layout from '../components/layout/Layout'
import getAllProjects from '../hooks/useLandingPageItemsQuery'
import { filterById, getSlug, showNumber, setGalleryImages } from '../utils'
import { defaultLang } from '../config'

export default function Projects({ location, data, pageContext }) {
  const { allProjects } = getAllProjects()
  const categories = data.categories.edges.sortByNumber()
  const anchor = location.hash.replace('#', '')
  const tabIndex = categories.findIndex(({ node }) => anchor === getSlug(node.fields.slug))
  const tabState = tabIndex !== -1 ? tabIndex : null
  const [hoverIndex, setHoverIndex] = useState(0)
  const [openTab, setOpenTab] = useState(tabState)
  const changeTabHandler = i => {
    setOpenTab(openTab === i ? null : i)
    window.location.hash = '#' + getSlug(categories[i].node.fields.slug)
  }

  useEffect(() => {
    setOpenTab(tabState)
  }, [location.hash])

  return (
    <Layout
      openTab={openTab}
      location={location}
      locale={pageContext.locale}
      locationPath={pageContext.locationPath}
    >
      {!!categories.length && (
        <BgImage
          className={'bgWrapper'}
          image={getImage(categories[hoverIndex].node.frontmatter.image)}
        >
          <div className={openTab === null ? 'projectsPageWrapper' : 'projectsPageWrapper-black'}>
            <div className="projectsPageContent">
              {categories.map(({ node }, i) => {
                const filteredProjects = filterById(allProjects, node.frontmatter.projects)
                if (pageContext.locale !== defaultLang) {
                  setGalleryImages(filteredProjects, allProjects)
                }
                return (
                  <div key={i} id={getSlug(node.fields.slug)}>
                    <div
                      role="presentation"
                      className={openTab === i || openTab === null ? 'projectsPageItem' : 'hidden'}
                      onClick={() => changeTabHandler(i)}
                      onMouseEnter={() => setHoverIndex(i)}
                    >
                      <span className="projectsPageItemNumber">[{showNumber(i + 1)}]</span>
                      <h1 className="projectsPageItemTitle">{node.frontmatter.title}</h1>
                    </div>
                    {openTab === i && (
                      <CategoryItemsList
                        locale={pageContext.locale}
                        data={filteredProjects}
                        changeTabHandler={changeTabHandler}
                      />
                    )}
                  </div>
                )
              })}
              <div className="projectsPageCategoryBox">
                {categories.map(({ node }, i) => (
                  <div
                    role="presentation"
                    key={i}
                    className={
                      openTab === i || openTab === null ? 'hidden' : 'projectsPageCategoryTitle'
                    }
                    onClick={() => changeTabHandler(i)}
                  >
                    <h1>{node.frontmatter.title}</h1>
                    <ArrowLight />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BgImage>
      )}
    </Layout>
  )
}
export const query = graphql`
  query ($categoriesList: [String]) {
    categories: allMarkdownRemark(filter: { frontmatter: { id: { in: $categoriesList } } }) {
      edges {
        node {
          frontmatter {
            id
            number
            title
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            projects
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
